<template>
  <div id="section-to-print" class="w-full section-to-print">
    <div class="flex justify-between items-center mt-20"><h2 class="my-3 capitalize">Invoice</h2><h5
      class="font-semibold">Invoice # {{invoice_id}}</h5></div>
    <div>
      <div>To,</div>
      <div>{{invoice.to}}</div>
      <div>{{invoice.receiverBusinessName}}</div>
      <div>{{invoice.receiverAddress}}</div>
    </div>

    <div class="mt-2">
      <table class="w-full table-with-border">
        <thead>
        <tr>
          <th style="width: 40%;">Description</th>
          <th style="width: 20%;">Quantity(Ton)</th>
          <th style="width: 20%;">Unit Price(TK)</th>
          <th style="width: 20%;">Total(TK)</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in invoice.items">
          <td>{{item.description}}</td>
          <td>{{item.quantity}}</td>
          <td>{{item.rate}}</td>
          <td>{{item.total}}</td>
        </tr>
        </tbody>
      </table>
      <div class="flex justify-end">
        <div class="text-center mr-5 mt-2" style="width: 20%;">
          Sub Total
        </div>
        <div class="text-center" style="width: 20%;">
          {{invoice.itemsTotal}}
        </div>
      </div>
      <h4 class="text-center my-3">Adjustments</h4>
      <div>

        <div class="flex justify-end" v-for="item in invoice.adjustments">
           <div class="text-right">{{item.title}}
             <span v-if="item.unit_price">({{item.quantity}} Liter @{{item.unit_price}})</span>
           </div>
          <div class="text-center border vs-divider-border-light" style="width: 20%;">
            {{item.amount}}
          </div>
        </div>

        <div class="flex justify-end">
          <div>
            Adjusted Price
          </div>
          <div class="text-center border vs-divider-border-light" style="width: 20%;">
            {{invoice.adjustedPrice}}
          </div>
        </div>

      </div>
    </div>
    <h1 class="uppercase pt-2 my-2">
      {{invoice.title}}</h1>
    <h5 class="mb-10 uppercase">{{invoice.subtitle}}</h5>
    <table>
      <tr>
        <th>Account Name</th>
        <td class="px-10">:</td>
        <td class="capitalize">{{invoice.accountName}}</td>
      </tr>
      <tr>
        <th>Account No.</th>
        <td class="px-10">:</td>
        <td class="capitalize">{{invoice.accountNo}}</td>
      </tr>
      <tr>
        <th>Bank Name</th>
        <td class="px-10">:</td>
        <td class="capitalize">{{invoice.bankName}}</td>
      </tr>
      <tr>
        <th>Brunch Name</th>
        <td class="px-10">:</td>
        <td class="capitalize">{{invoice.brunchName}}</td>
      </tr>
      <tr>
        <th>Brunch Address</th>
        <td class="px-10">:</td>
        <td class="capitalize">{{invoice.brunchAddress}}</td>
      </tr>
      <tr>
        <th>Routing No.</th>
        <td class="px-10">:</td>
        <td class="capitalize">{{invoice.routingNo}}</td>
      </tr>
    </table>
    <div class="my-10">
      {{invoice.description}}
    </div>
    <div class="mt-20">
      <div>{{invoice.senderName}}</div>
      <div>{{invoice.senderDesignation}}</div>
      <div>{{invoice.senderBusinessName}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrintInvoice',
  props: {
    invoice: {
      type: Object,
      required: true
    },
    invoice_id: '',
  }
}
</script>
