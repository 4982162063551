<template>
  <div>
    <div class="flex justify-between items-center my-2">
      <h3>Invoices</h3>
      <a href="/invoices/add">
        <vs-button name="button">
          Add Invoice
        </vs-button>
      </a>
    </div>

    <vs-card>
      <vs-table pagination :max-items="invoices.length" :data="invoices"

      >
        <template slot="thead">
          <vs-th>Invoice No.</vs-th>
          <vs-th>Invoice Title</vs-th>
          <vs-th>Action</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :data="invoice" :key="indextr" v-for="(invoice, indextr) in data">
            <vs-td>{{ invoice.invoice_no }}</vs-td>
            <vs-td>{{ JSON.parse(invoice.invoice_data).title }}</vs-td>
            <vs-td>
              <vs-button color="primary"
                         size="small"
                         @click="viewInvoice(invoice)"
                         class="mr-1"
              >View
              </vs-button>
              <vs-button class="mr-1"
                         size="small" @click="$router.push('/invoices/' + invoice.id + '/edit')">Edit
              </vs-button>
              <vs-button color="danger"
                         size="small"
                         @click="deleteItem(invoice)"
                         class="mr-1"
              >Delete
              </vs-button>
              <vs-button color="warning"
                         class="mr-1"
                         size="small"
                         @click="printInvoice(invoice)">Print
              </vs-button>

              <vs-button class="mr-1"
                         size="small" @click="$router.push('/invoices/' + invoice.id + '/duplicate')">Duplicate</vs-button>

            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>

    <vs-popup class="holamundo" title="Invoice" :active.sync="openInvoiceModal"
              fullscreen
    >
      <div class="w-full">
        <div class="w-full">
          <div class="w-full">
            <PrintInvoice :invoice="selectedInvoice" :invoice_id="invoice_id"/>
          </div>
        </div>
      </div>
    </vs-popup>

    <div class="only_print">
      <PrintInvoice :invoice="selectedInvoice" :invoice_id="invoice_id"
      />
    </div>


  </div>
</template>

<script>
import PrintInvoice from "@/views/invoice/PrintInvoice.vue";

export default {
  components: {PrintInvoice},
  data() {
    return {
      invoices: [],
      selectedInvoice: "",
      invoice_id: "",
      openInvoiceModal: false,
      test: [],
    }
  },
  methods: {
    getInvoices: function () {
      this.axios.get("/invoices").then((res) => {
        this.invoices = res.data
      }).catch(function (t) {
        return console.log(t)
      })
    },
    printInvoice: function (t) {
      this.selectedInvoice = JSON.parse(t.invoice_data),
        this.invoice_id = t.invoice_no,
        setTimeout(function () {
          window.print()
        }, 500);
      console.log({
        invoice: t
      })
    },
    viewInvoice: function (t) {
      this.selectedInvoice = JSON.parse(t.invoice_data);
      this.invoice_id = t.invoice_no;
      this.openInvoiceModal = true;
    },
    deleteItem: function (t) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Confirm",
        text: "Invoice NO. ".concat(t.invoice_no, " will be deleted!"),
        accept: () => {
          return this.acceptAlert(t.id)
        }
      })
    },
    acceptAlert: function (t) {

      this.axios.delete("/invoices/" + t).then((res) => {
        let status = res.data.status;

        if (status === 'success') {
          this.$vs.notify({
            color: "success",
            title: "Invoice deleted"
          })

          this.getInvoices()
        }

      }).catch(function (t) {
        return console.log(t)
      })
    }
  },
  mounted: function () {
    this.getInvoices()
  }
}
</script>
